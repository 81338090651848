import React from 'react';
import clsx from 'clsx';
import * as flags from '../../assets/flags';
import { useTranslation } from 'react-i18next';

export type FlagsCode = keyof typeof flags;

export interface TeamWithFlagProps {
  teamCode: FlagsCode;
  variant?: 'regular' | 'bold';
  reverse?: boolean;
  className?: string;
  numberToDisplay?: number;
}

/*
Workaround for translate key, see https://github.com/i18next/i18next-parser#caveats
t('flag:AT')
t('flag:BE')
t('flag:CH')
t('flag:DE')
t('flag:CZ')
t('flag:HR')
t('flag:HU')
t('flag:DK')
t('flag:ES')
t('flag:FI')
t('flag:FR')
t('flag:GB_ENG')
t('flag:GB_WLS')
t('flag:GB_SCT')
t('flag:IT')
t('flag:MK')
t('flag:NL')
t('flag:PL')
t('flag:PT')
t('flag:RU')
t('flag:SE')
t('flag:SK')
t('flag:TR')
t('flag:UK')
t('flag:UNKNOWN')
*/
export const TeamWithFlag: React.FC<TeamWithFlagProps> = ({
  teamCode = 'UNKNOWN',
  variant = 'bold',
  reverse = false,
  className,
  numberToDisplay,
  ...props
}) => {
  const { t } = useTranslation(['flag']);
  const flagName = t(`flag:${teamCode}`);

  const Flag = flags[teamCode];

  const iconVariants = {
    bold: 'h-4 w-6',
    regular: 'h-4 w-6  md:h-8 md:w-12',
  };
  const iconClasses: string = clsx(reverse ? 'mr-4' : 'ml-4', iconVariants[variant]);

  const labelVariants = {
    bold: 'font-black',
    regular: 'font-semibold',
  };
  const labelClasses: string = clsx(
    'text-black',
    {
      'text-right': !reverse,
    },
    labelVariants[variant],
  );

  const wrapperClassname = clsx(
    'flex',
    'items-center',
    {
      'flex-row-reverse': reverse,
    },
    className,
  );
  return (
    <div className={wrapperClassname} {...props}>
      {numberToDisplay ? <div className="text-gray-500 m-2 font-medium">({numberToDisplay})</div> : null}
      <span className={labelClasses}>{flagName}</span>
      <Flag className={iconClasses} />
    </div>
  );
};
